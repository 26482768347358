html {
  position: relative;
  min-height: 100%;
}
body {
  padding-bottom: 40px;
}

.footer {
  opacity: 0.95;
  position: absolute;
  bottom: 0;
  color: #fff;
  width: 100%;
}