.rect-img-container {
  position: relative;
}

.rect-img-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.rect-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

blockquote.blockquote {
  border-left: 5px solid #ecf0f1;
  padding-left: 10px;
}